
import { defineComponent, ref, reactive, computed, onMounted, nextTick } from "vue";
import { translate } from "@/core/plugins/i18n";
import TableDeliveryModule from "@/store/modules/deliverysale/modules/table";
import moment from "moment";
import { getModule } from "vuex-module-decorators";
import NewDeliveryModule from "@/store/modules/deliverysale/modules/new-delivery";
import _ from "lodash";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ViewTableDeliveryModule from "@/store/modules/deliverysale/modules/table-view-table/";
import Datatable from "@/components/c-datatable/Datatable.vue";
import Datarow from "@/components/c-datatable/Datarow.vue";
import ModuleMaster from "@/components/c-module-master/module-master.vue";
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue"
import { formatToDatatable } from "@/common/formaterToDatatable";
import NewEventModal from "./components/AddDeliveries.vue"
import { debounce } from "lodash";
import { useRouter } from "vue-router";
import * as Validations from "yup";
import { Field, Form } from "vee-validate";
import DatePickerRange from "@/components/forms/DatePickerRange.vue"
import DeliveryViewTableRow from "@/models/delivery/DeliveryViewTableRows";
import ApiService from "@/core/services/ApiService";
import { getMessageError } from "@/core/helpers/messageFromPulmeros";
import SwalMessageService from "@/core/services/SwalMessageService";

export default defineComponent({
  name: "calendar-app-1",
  components: {
     Datatable
    , Datarow
    , ModuleMaster
    , NewEventModal
    , Field
    , Form
    , DatePickerRange
    , SelectFilterRemote
  },
  setup() {
  const router = useRouter();
  const openModalNewEvent = ref(HTMLButtonElement);
  const moduleTable = getModule(ViewTableDeliveryModule);
  const moduleNewDelivery = getModule(NewDeliveryModule);
  const messageService = new SwalMessageService();
  const formSearch = ref();
  const idSale = ref("");
  const SaleDelivery = ref<DeliveryViewTableRow>();

  const validationSchema = Validations.object().shape({
            folio: Validations.string().nullable().default(null).label("folio"),
            statusId: Validations.string().nullable().default("-1").label("statusId")
  });

  const searchFilter = (data: any) => {
            moduleTable.UPDATE_FILTERS({
                driverid: data.agenteId
                , status: data.statusId,
                invoice: data.folio,
                PlanDateInit: data.PlanDateInit,
                PlanDateEnd: data.PlanDateEnd
            });
        }

  const filterNameChange = debounce((filterName: string) => {
            moduleTable.UPDATE_FILTERS({filterName});
        }, 550, {leading: false, trailing: true});
        
  const loadSales = () => {
      moduleTable.SEARCH_TABLE_DATA();
  }

  const changeView = () => {
    router.push({
              name: "/deliveries",
              params: {
              }
          });
  }

  /**
   * CONFIGURACIONES DE LA TABLA
   */
    const configTable = reactive([
      { PropName: "id", HeadLabel: "id", Type: "text", VisibleInGrid: false },
      { PropName: "invoince", HeadLabel: "Folio", Type: "text", VisibleInGrid: true },
      { PropName: "customerName", HeadLabel: "Cliente", Type: "text", VisibleInGrid: true },
      { PropName: "receiver", HeadLabel: "Recibe", Type: "text", VisibleInGrid: true },
      { PropName: "agentName", HeadLabel: "Agente", Type: "text", VisibleInGrid: true },
      { PropName: "saleDeliveryPlanAt", HeadLabel: "Fecha de Entrega", Type: "text", VisibleInGrid: true },            
      { PropName: "deliveryStatusName", HeadLabel: "Estatus", Type: "text", VisibleInGrid: true },            
      { PropName: "createdAt", HeadLabel: "Fecha de creación", Type: "text", VisibleInGrid: true },            
  ]);

  const labels = reactive({
      placeHolderToFieldSearch: "Buscar Venta",
      titleNewCrudAction: "Nueva Venta(ALT+N)",
  });

  const handleChangePage = (page: number) => {
    moduleTable.CHANGE_PAGE({ currentPage: page });
  }

  const downloadEvidence = (id, name) => {
      ApiService.get(`SaleDelivery`,`${id}/DownloadEvidence`).then(res => {
        const byteCharacters = atob(res.data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/png' }); // Cambia el tipo de imagen según corresponda

        // Crear un enlace temporal para descargar la imagen
        const url = URL.createObjectURL(blob);
        const enlace = document.createElement('a');
        enlace.href = url;
        enlace.download = name;

        // Agregar el enlace al documento y hacer clic en él para iniciar la descarga
        document.body.appendChild(enlace);
        enlace.click();

        // Limpiar
        document.body.removeChild(enlace);
        URL.revokeObjectURL(url);
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
  }

  const handleCrudAction = (action: string | number = "N", id: string) => {
    const sale = records.value.find(ir => ir.id === id);
    if(action == "P"){

    
    
    if(sale){
      
      SaleDelivery.value = {
        receiver: "hey",
        observation: ""
      } as DeliveryViewTableRow;

      idSale.value = id;
      SaleDelivery.value = sale;

      if(openModalNewEvent.value) {
            (openModalNewEvent.value as unknown as HTMLButtonElement).click();
      }
    }
  }else{
    let nameFile = `evidencia-${sale?.invoince}.png`;
    downloadEvidence(id,nameFile);
  }
  }

  //VARIABLES COMPUTADAS
  const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
  });
       

    const submitForm = (data) => {
      var fecha = new Date(data.dateDeliveries + "T" + data.timeDeliveries + "z");
     
        moduleNewDelivery.SAVE_NEW_DELIVERY({
          id: data.salesId,
          observation: data.observation,
          receiver: data.receiver,
          saleDeliveryPlanAt: fecha.toISOString(),
          userAgentId: data.agenteId
        }).then(() => {
          // currentDateEnd.value = '';
          // currentDateStart.value = '';

          // calendarOptions.value.events = [];

        });

        // formAdd.value.resetForm();
    }

    //Metodos
    const searchSales = (filterName: string) => {
      moduleNewDelivery.GET_DELIVERY_PLAN(filterName);
    }

    const searchAgent = (filterName: string) => {
      moduleNewDelivery.GET_AGENTS(filterName);
    }

    const renderRows = computed(() => {
        return formatToDatatable(moduleTable.records, configTable);
    });

    //VARIABLES COMPUTADAS
    const ItemsAgents = computed(() => moduleNewDelivery.getAgentsOptions);
    const ItemsSales = computed(() => moduleNewDelivery.getSalesToPlanOptions);
    const loadingSalesCombo = computed(() => moduleNewDelivery.isLoadingSales);
    const loadingAgentsCombo = computed(() => moduleNewDelivery.isLoadingAgents);


    const records = computed(() => moduleTable.records);
    const totalRecords = computed(() => moduleTable.totalRecords);
    const sizePage = computed(() => moduleTable.pagination.pageSize);

    onMounted(() => {
      setCurrentPageBreadcrumbs(translate('BREADCRUMB.DELIVERYSALE'), [translate('BREADCRUMB.MODULES')]);
      formSearch.value.setFieldValue('statusId', "-1");
      loadSales();
    });

    return {
      records,
      totalRecords,
      configTable,
      sizePage,
      labels,
      router,
      renderRows,
      filterNameChange,
      headers,
      openModalNewEvent,
      validationSchema,
      formSearch,
      idSale,
      SaleDelivery,

      changeView,
      loadSales,
      handleChangePage,
      handleCrudAction,
      searchFilter,
      searchAgent,
      downloadEvidence,


      ItemsAgents
      , ItemsSales
      , loadingSalesCombo
      , loadingAgentsCombo

      , submitForm
      , searchSales
    };
  },
});
